.school-input-container {
    position: absolute;
    top: 20%; /* Adjust the top position as needed */
    left: 20%;
  }
  
  .dynamic-box {
    width: 100%;
    padding: 20px;
    background: white;
    border-radius: 10px;
    text-align: center;
    margin-top: 20%; /* Add margin to avoid overlap */
  }
  
  .step-message {
    padding: 20px;
    font-size: 18px;
  }
  
  .screener-card {
    position: relative;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    background: #f8f9fa;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: 0.3s;
  }
  
  .screener-card:hover {
    background: #e9ecef;
  }
  
  .screener-card.selected {
    border-color: #007bff;
    background: #d0e6ff;
  }

  .back-button {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px; /* Add padding for better spacing */
    border-radius: 5px; /* Add border-radius for rounded corners */
    background-color: #6c757d; /* Set background color */
    color: white; /* Set text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s; /* Add transition for hover effect */
  }
  
  .back-button:hover {
    background-color: #5a6268; /* Darken background color on hover */
  }

  .class-selection-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  
  .class-table-card {
    width: 70%;
    padding: 15px;
    background: white;
    border-radius: 8px;
  }
  
  .export-button-container {
    width: 25%;
    text-align: center;
  }

  .export-button {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    background: #4CAF50;
    border: none;
    color: white;
    border-radius: 5px;
    transition: 0.3s;
  }

  .export-button:hover {
    background: #45a049;
  }

  .email-button {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    background: #4c61af;
    border: none;
    color: white;
    border-radius: 5px;
    transition: 0.3s;
    margin-bottom: 5%;
  }

  .email-button:hover {
    background: #454ba0;
  }

  .screener-button {
    width: 100%;
    padding: 15px;
    background: #f8f9fa;
    border: 2px solid transparent;
    border-radius: 8px;
    text-align: center;
    transition: 0.3s;
    font-size: 16px;
    cursor: pointer;
  }
  
  .screener-button:hover {
    background: #e9ecef;
  }
  
  .screener-button.selected {
    border-color: #007bff;
    background: #d0e6ff;
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .loading-container span {
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
  }
  